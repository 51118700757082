<template>
  <v-container class="px-10">
    <v-card
      elevation="2"
    >
      <v-row
        class="mx-3"
      >
        <v-col
          v-if="dataDashboard.customer_recap.enable"
          cols="12"
          sm="6"
          md="4"
        >
          <v-row>
            <v-col>
              <b>
                {{ dataDashboard.customer_recap.title }}
              </b>
            </v-col>
          </v-row>
          <v-divider />
          <v-row>
            <v-col
              v-for="(item, index) in dataDashboard.customer_recap.data"
              :key="index"
            >
              {{ item.label }}
            </v-col>
          </v-row>
          <v-row>
            <v-col
              v-for="(item, index) in dataDashboard.customer_recap.data"
              :key="index"
            >
              <p style="font-size: 20px;">
                <b>
                  {{ item.value | dotThousandSeparator }}
                </b>
              </p>
            </v-col>
          </v-row>
        </v-col>

        <v-col
          v-if="dataDashboard.media_recap.enable"
          cols="12"
          sm="6"
          md="4"
        >
          <v-row>
            <v-col>
              <b>
                {{ dataDashboard.media_recap.title }}
              </b>
            </v-col>
          </v-row>
          <v-divider />
          <v-row>
            <v-col
              v-for="(item, index) in dataDashboard.media_recap.data"
              :key="index"
            >
              {{ item.label }}
            </v-col>
          </v-row>
          <v-row>
            <v-col
              v-for="(item, index) in dataDashboard.customer_recap.data"
              :key="index"
            >
              <p style="font-size: 20px;">
                <b>
                  {{ item.value | dotThousandSeparator }}
                </b>
              </p>
            </v-col>
          </v-row>
        </v-col>

        <v-col
          v-if="dataDashboard.partner_recap.enable"
          cols="12"
          sm="6"
          md="4"
        >
          <v-row>
            <v-col>
              <b>
                {{ dataDashboard.partner_recap.title }}
              </b>
            </v-col>
          </v-row>
          <v-divider />
          <v-row>
            <v-col
              v-for="(item, index) in dataDashboard.partner_recap.data"
              :key="index"
            >
              {{ item.label }}
            </v-col>
          </v-row>
          <v-row>
            <v-col
              v-for="(item, index) in dataDashboard.partner_recap.data"
              :key="index"
            >
              <p style="font-size: 20px;">
                <b>
                  {{ item.value | dotThousandSeparator }}
                </b>
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>

    <!-- <v-row>
      <v-spacer class="py-3" />
    </v-row> -->
    <template v-if="isAuth">
      <v-row>
        <v-col
          v-if="dataDashboard.search_query.enable"
          cols="12"
          sm="6"
          md="4"
        >
          <v-card
            elevation="2"
          >
            <p
              class="text-center pt-5"
              style="
              color: black;
              font-weight: 500;
              font-size: 18px;
            "
            >
              {{ dataDashboard.search_query.title }}
            </p>
            <v-simple-table
              class="mx-3 pt-3 pb-10"
              :height="300"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      <p style="color:black; font-weight:500;">
                        Kata Kunci
                      </p>
                    </th>
                    <th class="text-left">
                      <p style="color:black; font-weight:500;">
                        Jumlah
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in dataDashboard.search_query.data"
                    :key="item.name"
                  >
                    <td>{{ item.label }}</td>
                    <td>{{ item.value | dotThousandSeparator }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>

        <v-col
          v-if="dataDashboard.ticket_recap.enable"
          cols="12"
          sm="6"
          md="4"
        >
          <v-card
            elevation="2"
          >
            <p
              class="text-center pt-5"
              style="
            color: black;
            font-weight: 500;
            font-size: 18px;
            "
            >
              {{ dataDashboard.ticket_recap.title }}
            </p>
            <v-simple-table
              class="mx-3 pt-3 pb-10"
              :height="300"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      <p style="color:black; font-weight:500;">
                        Kategori
                      </p>
                    </th>
                    <th class="text-left">
                      <p style="color:black; font-weight:500;">
                        Jumlah
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in dataDashboard.ticket_recap.data"
                    :key="item.name"
                  >
                    <td>{{ item.label }}</td>
                    <td>{{ item.value | dotThousandSeparator }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>

        <v-col
          v-if="dataDashboard.top_customer_category.enable"
          cols="12"
          sm="6"
          md="4"
        >
          <v-card
            elevation="2"
          >
            <p
              class="text-center pt-5"
              style="
            color: black;
            font-weight: 500;
            font-size: 18px;
            "
            >
              {{ dataDashboard.top_customer_category.title }}
            </p>
            <v-simple-table
              class="mx-3 pt-3 pb-10"
              :height="300"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      <p style="color:black; font-weight:500;">
                        Nama Penyewa
                      </p>
                    </th>
                    <th class="text-left">
                      <p style="color:black; font-weight:500;">
                        Jumlah
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in dataDashboard.top_customer_category.data"
                    :key="item.name"
                  >
                    <td>{{ item.label }}</td>
                    <td>{{ item.value | dotThousandSeparator }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>

      <!-- <v-row>
      <v-spacer class="py-3" />
    </v-row> -->

      <v-row>
        <v-col
          v-if="dataDashboard.top_customer_spend.enable"
          cols="12"
          sm="6"
          md="4"
        >
          <v-card
            elevation="2"
          >
            <p
              class="text-center pt-5"
              style="
            color: black;
            font-weight: 500;
            font-size: 18px;
            "
            >
              {{ dataDashboard.top_customer_spend.title }}
            </p>
            <v-simple-table
              class="mx-3 pt-3 pb-10"
              :height="300"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      <p style="color:black; font-weight:500;">
                        Nama Partner
                      </p>
                    </th>
                    <th class="text-left">
                      <p style="color:black; font-weight:500;">
                        Jumlah
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in dataDashboard.top_customer_spend.data"
                    :key="item.name"
                  >
                    <td>{{ item.label }}</td>
                    <td>Rp. {{ item.value | dotThousandSeparator }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>

        <v-col
          v-if="dataDashboard.top_partner_sales.enable"
          cols="12"
          sm="6"
          md="4"
        >
          <v-card
            elevation="2"
          >
            <p
              class="text-center pt-5"
              style="
            color: black;
            font-weight: 500;
            font-size: 18px;
            "
            >
              {{ dataDashboard.top_partner_sales.title }}
            </p>
            <v-simple-table
              class="mx-3 pt-3 pb-10"
              :height="300"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      <p style="color:black; font-weight:500;">
                        Nama Partner
                      </p>
                    </th>
                    <th class="text-left">
                      <p style="color:black; font-weight:500;">
                        Jumlah
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in dataDashboard.top_partner_sales.data"
                    :key="item.name"
                  >
                    <td>{{ item.label }}</td>
                    <td>Rp. {{ item.value | dotThousandSeparator }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>

        <v-col
          v-if="dataDashboard.partner_sharing_percentage.enable"
          cols="12"
          sm="6"
          md="4"
        >
          <v-card
            elevation="2"
          >
            <p
              class="text-center pt-5"
              style="
            color: black;
            font-weight: 500;
            font-size: 18px;
            "
            >
              {{ dataDashboard.partner_sharing_percentage.title }}
            </p>
            <v-simple-table
              class="mx-3 pt-3 pb-10"
              :height="300"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      <p style="color:black; font-weight:500;">
                        Nama Company
                      </p>
                    </th>
                    <th class="text-left">
                      <p style="color:black; font-weight:500;">
                        Pesan
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in dataDashboard.partner_sharing_percentage.data"
                    :key="item.name"
                  >
                    <td>{{ item.label }}</td>
                    <td>{{ item.value }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          v-if="dataDashboard.partner_contract_expired_soon.enable"
          cols="12"
          sm="6"
          md="4"
        >
          <v-card
            elevation="2"
          >
            <p
              class="text-center pt-5"
              style="
            color: black;
            font-weight: 500;
            font-size: 18px;
            "
            >
              {{ dataDashboard.partner_contract_expired_soon.title }}
            </p>
            <v-simple-table
              class="mx-3 pt-3 pb-10"
              :height="300"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      <p style="color:black; font-weight:500;">
                        Nama Partner
                      </p>
                    </th>
                    <th class="text-left">
                      <p style="color:black; font-weight:500;">
                        Tanggal Expired
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in dataDashboard.partner_contract_expired_soon.data"
                    :key="item.name"
                  >
                    <td>{{ item.label }}</td>
                    <td>{{ item.value }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </template>

    <!-- <v-row>
      <v-col>
        Pemesanan dan Pemasangan
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <line-chart
          :chart-data="lineChartData"
          :options="lineChartOptions"
        />
      </v-col>
    </v-row>
    <v-row class="py-5">
      <v-col>
        <v-spacer />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        <pie-chart
          :chart-data="pieChartData"
          :options="pieChartOptions"
        />
      </v-col>
    </v-row> -->
  </v-container>
</template>

<script>
  import axios from 'axios'

  // import LineChart from './component/line-vuechart.js'
  // import PieChart from './component/pie-vuechart.js'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    name: 'DashboardDashboard',
    // components: { LineChart, PieChart },
    data: () => ({
      dataDashboard: {
        customer_recap: {
          data: [],
          enable: false,
          title: 'Rangkuman Penyewa',
        },
        media_recap: {
          data: [],
          enable: false,
          title: 'Rangkuman Media',
        },
        partner_recap: {
          data: [],
          enable: false,
          title: 'Rangkuman Partner',
        },
        search_query: {
          data: [],
          enable: false,
          title: 'Kata Kunci Terpopuler 60 hari terakhir',
        },
        ticket_recap: {
          data: [],
          enable: false,
          title: 'Rangkuman Tiket',
        },
        top_customer_category: {
          data: [],
          enable: false,
          title: 'Kategori Penyewa Terbanyak',
        },
        top_customer_spend: {
          data: [],
          enable: false,
          title: 'Partner Penjualan Tertinggi 30 hari terakhir',
        },
        top_partner_sales: {
          data: [],
          enable: false,
          title: 'Partner Penjualan Tertinggi 30 hari terakhir',
        },
        partner_sharing_percentage: {
          data: [],
          enable: false,
          title: 'Persentase Pembagian dengan Mitra',
        },
        partner_contract_expired_soon: {
          data: [],
          enable: false,
          title: 'Kontrak mitra yang akan segera berakhir',
        },
      },
      tableSharingHeight: '',
      tablePartnerSalesHeight: '',
      tableCustomerSpendHeight: '',
      tableCustomerCategoryHeight: '',
      tableTicketHeight: '',
      tableSearchHeight: '',
      tablePartnerContractExpHeight: '',
      isAuth: false,
      // // Line
      // lineChartData: {
      //   labels: [
      //     'January', 'February', 'Maret', 'April',
      //     'Mei', 'Juni', 'Juli', 'Agustus',
      //     'September', 'Oktober', 'November', 'Desember',
      //   ],
      //   datasets: [
      //     {
      //       label: 'Pemesanan',
      //       data: [12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3],
      //       backgroundColor: [
      //         'rgba(255, 99, 132, 0.2)',
      //       ],
      //       borderColor: [
      //         'rgba(255, 99, 132, 1)',
      //       ],
      //       borderWidth: 1,
      //     },
      //     {
      //       label: 'Pemasangan Berhasil',
      //       data: [21, 13, 22, 8, 11, 13, 12, 19, 3, 5, 22, 3],
      //       backgroundColor: [
      //         'rgba(82, 235, 52, 0.2)',
      //       ],
      //       borderColor: [
      //         'rgba(255, 159, 64, 1)',
      //       ],
      //       borderWidth: 1,
      //     },
      //   ],
      // },
      // lineChartOptions: {
      //   responsive: true,
      //   maintainAspectRatio: false,
      // },

      // // Pie
      // pieChartData: {
      //   labels: [
      //     'Open',
      //     'In Process',
      //     'Closed',
      //   ],
      //   datasets: [{
      //     data: [15, 65, 20],
      //     backgroundColor: [
      //       'rgba(255, 99, 132, 0.5)',
      //       'rgba(255, 255, 0, 0.5)',
      //       'rgba(30, 255, 0, 0.5)',
      //     ],
      //     borderColor: 'white',
      //     borderWidth: 1,
      //   }],
      // },
      // pieChartOptions: {

      // },
    }),

    computed: {
    },

    watch: {
    },

    mounted () {
      const userMenus = localStorage.getItem('userMenus').split(',')
      if (userMenus.includes('DASHBOARD_VIEW')) {
        this.isAuth = true
      }
      this.initialize(this.$route.params.id)
    },

    methods: {
      initialize (id) {
        const requestBody = {}
        axios.post('/t/bi', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.dataDashboard = res.data.data
          }
        }).catch((e) => {
        })
      },
    },
  }
</script>
